import React, { useState, useEffect, useRef } from 'react';

import Card from '../../containers/Card';
import Title from '../../components/Title';
import IconButton from '../../components/IconButton';

import { Table, Grid, Button, Modal, Message, Dropdown, Form, Responsive } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';

import { maskMoney, maskDate } from '../../services/masks';
import { getPaymentTypes, selectList as getPaymentTypeOptions } from '../../services/api/paymentTypes';

import { PrintedReceipt } from './Receipt';

import { connect } from 'react-redux';
import { getPersistor } from '@rematch/persist'
import { list as listSales } from '../../services/api/sales';
import { CSVLink } from "react-csv";

import ReactToPrint from 'react-to-print';

import moment from 'moment-timezone';
import styled from 'styled-components';

import * as Storage from '../../services/storage';

import CouponReceipt from './CouponReceipt';

const TitleContainer = styled.div`
  padding: 0;
  margin-top: -1em;
  background: transparent;
`;



const ReceiptModal = (props) => {
  const { toggle, sale } = props;
  const isOpen = props.isOpen || false;

  const componentRef = useRef();
  let couponReceiptRef = useRef();

  return (
    <>
      <Modal open={isOpen}>
        <Modal.Header>Segunda via de recibo</Modal.Header>
        <Modal.Content scrolling>
          <PrintedReceipt sale={sale} ref={componentRef} />
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={toggle}>Fechar</Button>
          <ReactToPrint
            trigger={() => <Button positive>Imprimir</Button>}
            content={() => componentRef.current}
          />
          <ReactToPrint
                    trigger={() => <Button secondary>Imprimir Cupom</Button>}
                    content={() => couponReceiptRef.current}
                  />
                  <div style={{ display: 'none' }}>
                    <CouponReceipt data={sale} ref={couponReceiptRef} />
                  </div>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const SalesList = (props) => {
  const [modalIsOpen, toggleModal] = useState(false);
  const [sales, setSales] = useState([]);
  const [allSales, setAllSales] = useState([]);
  const [currentSale, setCurrentSale] = useState({});

  const [csvSalesData, setCsvSalesData] = useState([]);
  const [csvSalesHeaders, setCsvSalesHeaders] = useState([]);

  const [filterMode, setFilterMode] = useState(false);

  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');

  const filterOptions = [
    {
      key: 1,
      value: "payment_type",
      text: "Forma de Pagamento"
    },
    {
      key: 2,
      value: "from_to_date",
      text: "De / Até"
    },
    {
      key: 3,
      value: "description",
      text: "Descrição"
    }
  ];

 

  const [selectedFilter, setSelectedFilter] = useState(filterOptions.find(filter => filter.key === 1));

  const { dispatch } = props;

  const [paymentTypeOptions, setPaymentTypeOptions] = useState([]);

  const openReceipt = (sale) => {
    setCurrentSale(sale);
    toggleModal(!modalIsOpen);
  };

 
  const getCsvSalesData = (salesData) => salesData.map(saleData => {
    let parsed_date;

    try {
      parsed_date = moment(saleData.created_at).tz('America/Sao_Paulo');
    } catch (e) {}

    const paymentTypeDescription = (saleData.payment_type && (saleData.payment_type.description || '')) || '';

    return {
      description: saleData.description,
      payer_name: saleData.payer.name,
      payment_type: paymentTypeDescription,
      created_at: parsed_date ? `${parsed_date.format('DD/MM/YYYY HH:mm')}` : '',
      original_amount: `R$ ${maskMoney(saleData.original_amount, true)}`
    }
  });

  useEffect(() => {
    (async () => {
      try {
        dispatch.loading.isLoading(true);

        setCsvSalesHeaders([
          { label: 'Descrição', key: 'description' },
          { label: 'Pagador', key: 'payer_name' },
          { label: 'Forma de Pagamento', key: 'payment_type' },
          { label: 'Data de Criação', key: 'created_at' },
          { label: 'Total', key: 'original_amount' }
        ]);

        const tenantId = Storage.get('uid');
        const selectedOwner = Storage.get('selected_owner');

        const salesData = await listSales(tenantId, selectedOwner);
        const parsedSalesData = salesData.filter(sale => sale.created_at && null !== sale.created_at);

        setSales(parsedSalesData);
        setAllSales(parsedSalesData);

        const getPaymentTypesResponse = await getPaymentTypes(tenantId, selectedOwner);

        setPaymentTypeOptions(getPaymentTypeOptions(getPaymentTypesResponse));
      } catch (e) {
        console.log(e);

        const persistor = getPersistor();

        dispatch({ type: 'RESET' });

        persistor.purge();
        persistor.flush();

        Storage.clear();

        props.history.push('/');
      } finally {
        dispatch.loading.isLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            <Button icon="plus" primary floated='right' title="Criar recibo" onClick={() => props.history.push('/admin/sales/simulator')} />
            <CSVLink data={csvSalesData}
              target="_blank"
              filename={`lista-de-recibos-de-vendas.csv`}
              separator={";"}
              enclosingCharacter={`'`}
              headers={csvSalesHeaders}
              onClick={event => {
                setCsvSalesData(getCsvSalesData(sales));

                return true;
              }}>
              <Button icon="file excel"
                {...(sales.length === 0 && { disabled: true })}
                secondary
                floated='right'
                title="Exportar para CSV" />
            </CSVLink>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <TitleContainer>
        <Title tag='h3'>Vendas</Title>
      </TitleContainer>
      <Card>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={4}>
              <label>Filtros</label>
              <div>
                <Dropdown
                  selection
                  search
                  fluid
                  value={selectedFilter.value}
                  onChange={(e, data) => {
                    setSelectedFilter(filterOptions.find(filter => filter.value === data.value));
                    setSales(allSales);
                    setFromDateFilter('');
                    setToDateFilter('');
                  }}
                  options={filterOptions}
                />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={12}>
              {selectedFilter.value === 'from_to_date' && (
                <>
                  <Grid stackable>
                    <Grid.Column mobile={8} tablet={8} computer={8}>
                      <label>De</label>
                      <div>
                        <DateInput
                          name='from_date_filter'
                          iconPosition="left"
                          value={fromDateFilter}
                          clearable
                          fluid
                          onChange={(e, { value }) => {
                            const date = maskDate(value);
                            const fromDate = date !== '' ? moment(date.split('/').reverse().join('-')).startOf('day').toDate() : '';
                            const toDate = toDateFilter !== '' ? moment(toDateFilter.split('/').reverse().join('-')).endOf('day').toDate() : '';

                            setFromDateFilter(date);

                            if ((value === '' && toDateFilter === '') || (value === '' && toDate === '' && fromDate === '')) {
                              setSales(allSales);
                              setFilterMode(false);
                            } else {
                              if (toDateFilter === '') {
                                setSales(
                                  allSales.filter(sale => {
                                    return moment(sale.created_at).toDate().getTime() >= fromDate.getTime();
                                  })
                                );
                              } else {
                                if (fromDate !== '') {
                                  setSales(
                                    allSales.filter(sale => {
                                      return moment(sale.created_at).toDate().getTime() >= fromDate.getTime() &&
                                        moment(sale.created_at).toDate().getTime() <= toDate.getTime();
                                    }
                                    )
                                  );
                                } else {
                                  setSales(
                                    allSales.filter(sale => {
                                      return moment(sale.created_at).toDate().getTime() <= toDate.getTime();
                                    })
                                  );
                                }
                              }
                              setFilterMode(true);
                            }
                          }}
                        />
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile={8} tablet={8} computer={8}>
                      <label>Até</label>
                      <div>
                        <DateInput
                          name='to_date_filter'
                          iconPosition="left"
                          value={toDateFilter}
                          clearable
                          fluid
                          onChange={(e, { value }) => {
                            const date = maskDate(value);
                            const fromDate = fromDateFilter !== '' ? moment(fromDateFilter.split('/').reverse().join('-')).startOf('day').toDate() : '';
                            const toDate = date !== '' ? moment(date.split('/').reverse().join('-')).endOf('day').toDate() : '';

                            setToDateFilter(date);

                            if ((value === '' && fromDateFilter === '') || (value === '' && toDate === '' && fromDate === '')) {
                              setSales(allSales);
                              setFilterMode(false);
                            } else {
                              if (fromDateFilter === '') {
                                setSales(
                                  allSales.filter(sale => {
                                    return moment(sale.created_at).toDate().getTime() <= toDate.getTime();
                                  })
                                );
                              } else {
                                if (toDate !== '') {
                                  setSales(
                                    allSales.filter(sale => {
                                      return moment(sale.created_at).toDate().getTime() >= fromDate.getTime() &&
                                        moment(sale.created_at).toDate().getTime() <= toDate.getTime();
                                    }
                                    )
                                  );
                                } else {
                                  setSales(
                                    allSales.filter(sale => {
                                      return moment(sale.created_at).toDate().getTime() >= fromDate.getTime();
                                    })
                                  );
                                }
                              }
                              setFilterMode(true);
                            }
                          }}
                        />
                      </div>
                    </Grid.Column>
                  </Grid>
                </>
              )}
              {selectedFilter.value === 'payment_type' && (
                <>
                  <label>Formas de Pagamento</label>
                  <div>
                    <Dropdown
                      selection
                      search
                      fluid
                      clearable
                      onChange={(e, { value }) => {
                        if (value === '') {
                          setSales(allSales);
                          setFilterMode(false);
                        } else {
                          setSales(allSales.filter(saleData => saleData.payment_type && saleData.payment_type.id === value));
                          setFilterMode(true);
                        }
                      }}
                      options={paymentTypeOptions}
                    />
                  </div>
                </>
              )}
              {selectedFilter.value === 'description' && (
                <Form>
                  <label>Descrição</label>
                  <Form.Field>
                    <div>
                      <input
                        type="text"
                        onChange={(e) => {
                          const text = e.target.value;

                          if (text.length === 0) {
                            setSales(allSales);
                            setFilterMode(false);
                          } else {
                            setSales(allSales.filter(saleData => saleData.description.includes(text)));
                            setFilterMode(true);
                          }
                        }} />
                    </div>
                  </Form.Field>
                </Form>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
      {(allSales.length > 0 && (filterMode && sales.length > 0)) || (allSales.length > 0 && !filterMode) ? (
        <>
          <Responsive fireOnMount as={Card}>
            <Table celled basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Descrição</Table.HeaderCell>
                  <Table.HeaderCell>Pagador</Table.HeaderCell>
                  <Table.HeaderCell>Forma de Pagamento</Table.HeaderCell>
                  <Table.HeaderCell>Data de Criação</Table.HeaderCell>
                  <Table.HeaderCell>Total</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {sales.map((sale, index) => {
                  let parsed_date;

                  try {
                    parsed_date = moment(sale.created_at).tz('America/Sao_Paulo');
                  } catch (e) {}

                  return (
                    <Table.Row key={index}>
                      <Table.Cell>{sale.description}</Table.Cell>
                      <Table.Cell>{sale.payer.name}</Table.Cell>
                      <Table.Cell>{sale.payment_type && sale.payment_type.description}</Table.Cell>
                      <Table.Cell>{parsed_date && `${parsed_date.format('DD/MM/YYYY')} às ${parsed_date.format('HH:mm:ss')}`}</Table.Cell>
                      <Table.Cell>R$ {`${maskMoney(sale.original_amount, true)}`}</Table.Cell>
                      <Table.Cell>
                        <IconButton title="Segunda via do Recibo" name="file alternate" onClick={() => openReceipt(sale)} />
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </Responsive>
          <ReceiptModal isOpen={modalIsOpen}
            sale={currentSale}
            toggle={() => toggleModal(! modalIsOpen)} />
        </>
      ) : (
          <Message negative>
            <p>{allSales.length > 0 && filterMode ? 'Registros não encontrados' : 'Ainda não existem vendas cadastradas'}</p>
          </Message>
        )
      }
    </>
  );
};

export default connect()(SalesList);
