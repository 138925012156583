import React, { useState, useEffect } from 'react';

import { Formik, Field } from 'formik';
import { Grid, Segment, Image, Button, Container as SemanticContainer, Message } from 'semantic-ui-react';
import { Link } from "react-router-dom";

import ModalForgotPassword from './ModalForgotPassword';

import Input from '../../components/Input';
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/ErrorMessage';
import Dropdown from '../../components/Dropdown';

import { getData as getTenantData } from '../../services/api/tenants';
import { getData as getOwnerData, listOwners } from '../../services/api/owners';
import { getPaymentTypes } from '../../services/api/paymentTypes';

import { connect } from 'react-redux';
import { authenticate } from '../../services/api/auth';

import * as Yup from 'yup';
import * as Storage from '../../services/storage';

import styled from 'styled-components';

const Container = styled(SemanticContainer)`
  margin-top: ${props => props.margintop} !important;
  width: 40% !important;
`;

const Logo = styled(Image)`
  width: 150px;
`;

const BorderlessSegment = styled(Segment)`
  border: none !important;
`;

const ModalLink = styled.a`
  cursor: pointer;
  margin-left: 15px;
`;

let initialValues;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail inválido')
    .required('O e-mail é obrigatório'),
  password: Yup.string()
    .required('A senha é obrigatória'),
  owner: Yup.string()
    .required('A empresa é obrigatória')
});

const redirectPage = (props, status, name) => {
  const { history } = props;

  if (status !== 'activated') {
    history.push({
      pathname: '/adherence-monitoring',
      state: {
        status,
        name
      }
    });
    return;
  }

  history.push({pathname: '/admin/sales', state: { status, name }});
}

const Login = (props) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [owners, setOwners] = useState([]);
  const [openModalForgotPassword, setOpenModalForgotPassword] = useState(false);
  const [isVisiblePassword, setIsVisiblePassword] = useState(true);

  const { updatePaymentTypes, updateOwner } = props;

  useEffect(() => {
    (async () => {
      initialValues = {
        email: '',
        password: '',
      };
      setLoading(true);

      const appOwners = await listOwners();
      const defaultOwner = appOwners.find(owner => owner.value === process.env.REACT_APP_DEFAULT_OWNER);

      setOwners(appOwners);
      updateOwner(defaultOwner);

      //if (appOwners.length === 1) {
        initialValues.owner = [defaultOwner].shift().value;
      //}
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEnter = (e, props) => {
    if (e.keyCode !== 13) {
      return;
    }

    props.submitForm();
  };

  const handleSubmit = async (values) => {
    const { email, password, owner: selectedOwner } = values;

    setLoading(true);

    try {
      const { refreshToken, idToken: token, uid: tenantId } = await authenticate(email, password);
      const tenant = await getTenantData(tenantId, selectedOwner);

      if (! tenant) {
        throw new Error('Client not found!');
      }
      const owner = await getOwnerData(selectedOwner);
      const payment_types = await getPaymentTypes(tenantId, selectedOwner);

      updatePaymentTypes(payment_types);

      Storage.set('uid', tenantId);
      Storage.set('refresh_token', refreshToken);
      Storage.set('token', token);
      Storage.set('description', (tenant.description || ''));

      Storage.set('selected_owner', selectedOwner);
      Storage.set('owner_name', owner.name);
      Storage.set('owner_document', owner.document);
      
      // Se tenant tem url especifica
      if (tenant.payment_url){
        Storage.set('payment_url', tenant.payment_url);
      }
      else{
        Storage.set('payment_url', owner.payment_url);
      }

      Storage.set('tenant_status', tenant.status);
      Storage.set('tenant_address', JSON.stringify(tenant.address));
      Storage.set('tenant_document', (tenant.document || ''));
      Storage.set('tenant_phone', (tenant.phone || ''));

      
      Storage.set('payment_by_link_url', owner.payment_by_link_url);
  

      Storage.set(
        'subacquirer_credentials',
        JSON.stringify({
          key_auth: owner.subacquirer_key_auth,
          token: owner.subacquirer_token,
          client_id: owner.subacquirer_client_id
        })
      );

      Storage.set(
        'tenant_credentials',
        JSON.stringify({
          client_id: tenant.tenant_client_id,
          token: tenant.tenant_authentication_token
        })
      );

      redirectPage(props, tenant.status, tenant.description);
    } catch (e) {
      setError('Login inválido!');
    }
    setLoading(false);
  };

  const handleForgotPassword = (e) => { setOpenModalForgotPassword(true); };

  const closeModalForgotPassword = (e) => { setOpenModalForgotPassword(false); };

  const InvalidLoginMessage = (props) => (
    <>
      <Message negative>
        <p>{props.children}</p>
      </Message>
    </>
  );

  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <>
      <Container {...(owners.length > 1 ? { 'margintop': '2.5em' } : { 'margintop': '6.5em' })}>
        <BorderlessSegment>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Logo src={process.env.REACT_APP_LOGIN_LOGO} centered />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {error && (
            <InvalidLoginMessage>{error}</InvalidLoginMessage>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleSubmit}>
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <label>E-Mail</label>
                      <div>
                        <Field name="email"
                          type="email"
                          component={Input}
                          onKeyUp={(e) => handleEnter(e, props)}
                          fluid />
                        {props.errors.email && (<ErrorMessage>{props.errors.email}</ErrorMessage>)}
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <label>Senha</label>
                      <div>
                        <Field 
                          name="password"
                          component={Input}
                          type={isVisiblePassword ? 'password' : 'text'}
                          icon={{ name: 'eye', link: true, onClick: (e) => setIsVisiblePassword(!isVisiblePassword) }}
                          onKeyUp={(e) => handleEnter(e, props)}
                          fluid />
                        {props.errors.password && (<ErrorMessage>{props.errors.password}</ErrorMessage>)}
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  {( process.env.REACT_APP_TYPE !== 'notary' && owners.length > 1) && (
                    <Grid.Row>
                      <Grid.Column>
                        <label>Segmento</label>
                        <div>
                          <Field name="owner"
                            type="text"
                            component={Dropdown}
                            options={owners}
                            fluid />
                          {props.errors.owner && (<ErrorMessage>{props.errors.owner}</ErrorMessage>)}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  )} 
                  <Grid.Row>
                    <Grid.Column>
                      {process.env.REACT_APP_TYPE === 'notary' && (
                        <Link to="/credentiation">
                          <Button type="button" color="orange" floated='left'>Quero me cadastrar</Button>
                        </Link>
                      )}
                      <Button primary type="submit" floated='right'>Entrar</Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </form>
            )}
          </Formik>
        </BorderlessSegment>
        <ModalLink onClick={handleForgotPassword}>Esqueci minha senha</ModalLink>
        <ModalForgotPassword
          open={openModalForgotPassword}
          closeModal={closeModalForgotPassword}
        />
      </Container>
    </>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  updatePaymentTypes: dispatch.payment_types.updatePaymentTypes,
  updateOwner: dispatch.owner.updateOwner
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
