import React, { Component, useRef } from 'react';
import styled from 'styled-components';

import { Grid, Header, Button, Table } from 'semantic-ui-react';
import { maskMoney } from '../../services/masks';
import { connect } from 'react-redux';
import { getPersistor } from '@rematch/persist'

import moment from 'moment-timezone';
import "../../../node_modules/moment/min/locales";

import * as Storage from '../../services/storage';
import Card from '../../containers/Card'
import CouponReceipt from './CouponReceipt';

import ReactToPrint from 'react-to-print';

moment.locale('pt-br');

const Block = styled.div`
  padding: 1.3em;
  line-height: 1.6em;
`;

const TitleContainer = styled.div`
  margin: 0 0 1em 0;
`;

const ScrollableContainer = styled.div`
  max-height: 33em;
  overflow-y: scroll;
`;

const PaymentBlockGrid = styled(Grid)`
  margin: 0 0 1em 0;
`;

const ColumnWithoutPadding = styled(Grid.Column)`
  padding: 0;
`;

const Signature = styled.div`
  padding-top: 30px;
`;

const ButtonWrapper = styled.div`
  padding-bottom: 1em;
  padding-top: 1em;
`;

const isNotary = () => process.env.REACT_APP_TYPE === 'notary';
const getBusinessType = () => isNotary() ? 'cartório' : 'estabelecimento';

const ReceiptHeader = () => {
  return (
    <Block>
      <Grid.Column>
        <TitleContainer>
          <Header>RECIBO DE TRANSAÇÃO DA OPERAÇÃO</Header>
        </TitleContainer>
      </Grid.Column>
    </Block>
  );
}

const PayeeInfo = (props) => {
  const { sale } = props;

  const { payee } = sale;
  const { address } = payee;

  return (
    <Block>
      <Grid.Column>
        <TitleContainer>
          <Header as='h4'>Empresa</Header>
        </TitleContainer>
      </Grid.Column>
      <Grid.Column>
        <strong>{payee.name}</strong>
      </Grid.Column>
      <Grid.Column>
        <strong>CPF / CNPJ:</strong> {payee.document}
      </Grid.Column>
      <Grid.Column>
        <strong>Telefone:</strong> {payee.phone}
      </Grid.Column>
      <Grid.Column>
        <strong>Endereço:</strong> {`${address.street}, ${address.number} - ${address.city}, ${address.state}`}
      </Grid.Column>
    </Block>
  )
};

const OwnerInfo = () => {
  const owner_name = Storage.get('owner_name');
  const owner_document = Storage.get('owner_document');

  return (
    <Block>
      <Grid.Column>
        <TitleContainer>
          <Header as='h4'>Vendedor</Header>
        </TitleContainer>
      </Grid.Column>
      <Grid.Column>
        <strong>{owner_name}</strong>
      </Grid.Column>
      <Grid.Column>
        {owner_document}
      </Grid.Column>
    </Block>
  );
};

const PayerInfo = (props) => {
  const { sale } = props;
  const { payer } = sale;

  return (
    <Block>
      <Grid.Column>
        <TitleContainer>
          <Header as='h4'>Cliente</Header>
        </TitleContainer>
      </Grid.Column>
      <Grid.Column>
        <strong>{payer.name}</strong>
      </Grid.Column>
      <Grid.Column>
        <strong>CPF</strong> {payer.document}
      </Grid.Column>
      <Grid.Column>
        <strong>Telefone:</strong> {payer.phone}
      </Grid.Column>
    </Block>
  )
};

const PaymentInfo = (props) => {
  const { sale } = props;
  const { payment_type } = sale;

  let parsed_date;

  try {
    parsed_date = moment(sale.created_at).tz('America/Sao_Paulo');
  } catch (e) {}

  return (
    <>
      <Block>
        <Grid.Column>
          <TitleContainer>
            <Header as='h4'>Dados de Pagamento</Header>
          </TitleContainer>
        </Grid.Column>
        <PaymentBlockGrid>
          <ColumnWithoutPadding width={9}>
            <strong>{payment_type.type === 'debit' ? 'Débito' : 'Crédito'}</strong>
          </ColumnWithoutPadding>
          <ColumnWithoutPadding width={3}>
            <strong>Data de Realização</strong>
          </ColumnWithoutPadding>
          <ColumnWithoutPadding width={4} textAlign="right">
            {parsed_date && `${parsed_date.format('DD/MM/YYYY')} às ${parsed_date.format('HH:mm:ss')}`}
          </ColumnWithoutPadding>
          <ColumnWithoutPadding width={16}>
            {`${payment_type.installments}x de R$ ${maskMoney(sale.installment_amount, true)}`}
          </ColumnWithoutPadding>
        </PaymentBlockGrid>
      </Block>
    </>
  );
};

const PaymentItems = (props) => {
  const { sale } = props;

  return (
    <>
      <Block>
        <Grid.Column>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Descrição</Table.HeaderCell>
                <Table.HeaderCell>Preço</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {sale.description}
                </Table.Cell>
                <Table.Cell>
                  {`R$ ${maskMoney(sale.original_amount, true)}`}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}></Grid.Column>
            <Grid.Column width={8}>
              <Table>
                <Table.Body>
                  <Table.Row active>
                    <Table.Cell>
                      <strong>{`Total destinado ao ${getBusinessType()}`}</strong>
                    </Table.Cell>
                    <Table.Cell>
                      <strong>{`R$ ${maskMoney(sale.original_amount, true)}`}</strong>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row active>
                    <Table.Cell>
                      <strong>Total a pagar parcelado</strong>
                    </Table.Cell>
                    <Table.Cell>
                      <strong>
                        {`R$ ${maskMoney(sale.total_with_interest, true)}`}
                      </strong>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row active>
                    <Table.Cell>
                      <strong>Custo financeiro da operação</strong>
                    </Table.Cell>
                    <Table.Cell>
                      <strong>
                        {`R$ ${maskMoney(sale.total_with_interest - sale.original_amount, true)}`}
                      </strong>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Block>
    </>
  );
};

const Footer = () => {
  return (
    <>
      <Block>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <div>
                <small>
                  {`Através do presente instrumento, fica confirmada a operação financeira destinada ao pagamento de emolumentos e outras despesas relacionadas ao ${getBusinessType().charAt(0).toUpperCase() + getBusinessType().slice(1)}`}.
                </small>
              </div>
              <div>
                <small>
                  O titular do cartão confirma a transação realizada e renuncia, de forma irretratável, ao direito de cancelar ou contestar a operação junto à operadora de cartão de crédito.
                </small>
              </div>
              <Signature>
                Assinatura do Cliente: ________________________________________
              </Signature>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Block>
    </>
  )
};

export const ReceiptData = (props) => {
  const { sale } = props;

  return (
    <>
      { sale && (
        <>
          <ReceiptHeader />
          <PayeeInfo sale={sale} />
          <OwnerInfo />
          <PayerInfo sale={sale} />
          <PaymentInfo sale={sale} />
          <PaymentItems sale={sale} />
          <Footer />
        </>
      )}
    </>
  );
}

const ReceiptForm = (props) => {
  const { dispatch, sale } = props;

  const handleFinish = () => {
    const persistor = getPersistor();

    dispatch({ type: 'RESET' });

    persistor.purge();
    persistor.flush();

    props.history.push('/admin/sales');
  };

  const componentRef = useRef();
  let couponReceiptRef = useRef();

  return (
    <>
      { sale && (
        <>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <ButtonWrapper>
                  <Button primary type="button" floated='right' onClick={handleFinish}>Concluir</Button>
                  <ReactToPrint
                    trigger={() => <Button secondary type="button" floated='right'>Imprimir</Button>}
                    content={() => componentRef.current}
                  />
                  <ReactToPrint
                    trigger={() => <Button secondary>Imprimir Cupom</Button>}
                    content={() => couponReceiptRef.current}
                  />
                  <div style={{ display: 'none' }}>
                    <CouponReceipt data={sale} ref={couponReceiptRef} />
                  </div>
                </ButtonWrapper>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Card>
                  <ScrollableContainer>
                    <>
                      <PrintedReceipt sale={sale} ref={componentRef} />
                    </>
                  </ScrollableContainer>
                </Card>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      )}
    </>
  );
};

const PrintedReceiptContainer = styled.div`
  @media print {
    @page {
      margin-top: 1cm;
      margin-bottom: 0.5cm;
      margin-left: 0.5cm;
      margin-right: 0.5cm;
    }

    .payment-container {
      page-break-after: always;
    }
  }
`;

export class PrintedReceipt extends Component {
  render() {
    return (
      <PrintedReceiptContainer>
        <ReceiptData sale={this.props.sale} />
      </PrintedReceiptContainer>
    )
  }
};

const mapStateToProps = state => ({
  sale: state.sale
});

export const Receipt = connect(mapStateToProps)(ReceiptForm);
