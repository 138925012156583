import React from 'react';

import { Grid, Header, Divider } from 'semantic-ui-react';

import styled from 'styled-components';

const ButtonContainer = styled.div`
  padding-bottom: 1em;
`;

const Title = (props) => {
  const tag = props.tag || 'h1';
  const withoutGrid = props.withoutGrid || false;

  const Content = () => (
    <Grid.Row>
      <Grid.Column>
        <ButtonContainer>
          <Grid.Row>
            <Grid.Column>
              <Header as={tag}>{props.children}</Header>
              <Divider clearing />
            </Grid.Column>
          </Grid.Row>
        </ButtonContainer>
      </Grid.Column>
    </Grid.Row>
  );

  if (! withoutGrid) {
    return (
      <Grid>
        <Content />
      </Grid>
    )
  }

  return ( <Content /> );
};

export default Title;
