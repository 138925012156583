import React, { useState, useEffect } from 'react';
import manual from '../files/manual_cartexpress.pdf';
import { Menu as SemanticMenu, Icon} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { getPersistor } from '@rematch/persist'

import styled from 'styled-components';

const AppMenuItemWrapper = styled.div`
  border-radius: none;
  color: #fff;
  fontSize: 1em;
  borderRadius: none;

  .ui.vertical.menu>.active.item:only-child {
    border-radius: none;
  }
`;

const AppMenu = styled(SemanticMenu)`
  background-color: #1b2e4e !important;
`;

const AppMenuItemIcon = styled(Icon)`
  float: left !important;
  margin: 0 !important;
  margin-right: 1em !important;
`;

const AppMenuItem = SemanticMenu.Item;

//Modal de notificacao de mudanca de link de pagamento
// const InfoModal = (props) => {
//   const {toggle} = props;
//   const isOpen = props.isOpen || false;
  
//   return (
//     <>
//       <Modal 
//           open={isOpen}  
//           backdrop="static" 
//           size="tiny">
//       <Modal.Header>Novidade Cartexpress!</Modal.Header>
//         <Modal.Content scrolling>
//         Agora temos link de pagamento.<br></br>
//         Habilite conosco essa nova modalidade.
//         </Modal.Content>
//         <Modal.Actions>
//           <Button primary onClick={toggle}>Fechar</Button>
//         </Modal.Actions>
//       </Modal>
//     </>
//   );
// };

const Menu = (props) => {
  const { dispatch } = props;

  const [options, setOptions] = useState([]);
  const [modalNewLink, toggleModalNewLink] = useState(false);


  // Show notification new payment link
  const viewedNotification = localStorage.getItem('viewed_notification');
  if(viewedNotification){
    toggleModalNewLink(modalNewLink);
  }
 

  const menuClickHandle = (_, menu) => {
    dispatch.sidebarIsVisible.closeSidebar();

    const persistor = getPersistor();

    persistor.purge();
    persistor.flush();

    dispatch({ type: 'RESET' });

    props.history.push(menu.name);
  };

  const menuClickHandleExternal = (_, menu) => {
    window.open(menu.name, '_blank');
  };

  const hasPaymentByLink = localStorage.getItem('payment_by_link_url');

  useEffect(() => {
    setOptions([
      {
        id: 1,
        icon: 'calculator',
        clickHandler: menuClickHandle,
        name: '/admin/simulator',
        active: props.history.location.pathname === '/admin/simulator',
        text: 'Simulador de venda'
      },
      {
        id: 2,
        icon: 'file alternate',
        clickHandler: menuClickHandle,
        name: '/admin/sales',
        active: props.history.location.pathname === '/admin/sales',
        text: 'Gerar recibo de venda'
      },
      {
        id: 3,
        icon: 'payment',
        clickHandler: menuClickHandleExternal,
        name: hasPaymentByLink,
        active: '',
        text: 'Pagamentos por link',
        isExternal: true
      },
      {
        id: 4,
        icon: 'chart line',
        clickHandler: menuClickHandleExternal,
        name: localStorage.getItem('payment_url'),
        active: '',
        text: 'Dashboard pagamentos',
        isExternal: true
      },
      {
        id: 5,
        icon: 'book',
        clickHandler: menuClickHandleExternal,
        name: manual,
        active: '',
        text: 'Manual do link',
        isExternal: true
      }
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history.location.pathname]);

  return (
    
    
    <AppMenu fluid vertical inverted borderless>

    {/* <InfoModal isOpen={! modalNewLink} toggle={() => toggleModalNewLink(!modalNewLink)} /> */}

      <AppMenuItemWrapper>
        { options.map((option, index) => {
          if (option.text !=='Pagamentos por link'){
            return (
              <AppMenuItem position="left"
                name={option.name}
                key={option.id}
                {...(option.active && { active: true })}
                as='a'
                onClick={option.clickHandler}>
                <AppMenuItemIcon name={option.icon} />
                { option.text }
              </AppMenuItem>
            )
          }

          if (hasPaymentByLink) {
            return (
              <AppMenuItem position="left"
                name={option.name}
                key={option.id}
                title='Habilite com nosso suporte'
                {...(option.active && { active: true })}
                as='a'
                onClick={option.clickHandler}>
                <AppMenuItemIcon name={option.icon} />
                { option.text }
              </AppMenuItem>
            )
          }

          return (<></>)
          
        }) }
      </AppMenuItemWrapper>
    </AppMenu>
    
  );
};

export default connect()(Menu);
