import * as Api from './api';

export const list = async (tenantId, ownerId) => {
  const headers = {
    alias: process.env.REACT_APP_PROJECT_ID,
    authorization: `Basic ${process.env.REACT_APP_PARCELA_API_AUTHORIZATION}`
  };

  const response = await Api.doRequest(`/owners/${ownerId}/tenants/${tenantId}/sales`, 'GET', headers);

  return response.data.data;
};

export const save = async (tenantId, ownerId, saleData) => {
  const headers = {
    alias: process.env.REACT_APP_PROJECT_ID,
    authorization: `Basic ${process.env.REACT_APP_PARCELA_API_AUTHORIZATION}`
  };

  const response = await Api.doRequest(`/owners/${ownerId}/tenants/${tenantId}/sales`, 'POST', headers, saleData);

  return response.data;
};
