import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Sidebar as Bar, Image, Grid, Responsive, Icon } from 'semantic-ui-react';
import { isAuthenticated } from '../services/auth';

import Menu from './Menu';
import Loader from '../components/Loader';
import footerLogo from '../powered_by_logo.png';
import styled from 'styled-components';

import * as Storage from '../services/storage';

const SidebarGrid = styled(Responsive)`
  background-color: #1b2e4e !important;
  overflow-x: hidden;
  overflow-y: hidden !important;
  box-shadow: none;
  height: 100%;
`;

const DivContact = styled(Responsive)`
  background-color: #1b2e4e !important;
  overflow-x: hidden;
  overflow-y: hidden !important;
  box-shadow: none;
  height: 100%;
  color: #fff;
  font-size: small;
  text-align: left;
`;

const DivEmail = styled(Responsive)`
  background-color: #1b2e4e !important;
  overflow-x: hidden;
  overflow-y: hidden !important;
  box-shadow: none;
  height: 100%;
  color: #fff;
  font-size: 12px;
  text-align: center;
`;

const DivTitle = styled(Responsive)`
  background-color: #1b2e4e !important;
  overflow-x: hidden;
  overflow-y: hidden !important;
  box-shadow: none;
  height: 100%;
  color: #fff;
  font-size: small;
  text-align: center;
  padding-top:350px
`;

const FooterLogoWrapper = styled.div`
  position: absolute;
  width: 8em;
  bottom: 8vh;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
`;

const Pushable = styled(Bar.Pushable)`
  height: 100vh;
  width: 100vw;
  display: table;
`;

const Logo = styled(Image)`
  width: 8em;
  padding-top: 2em;
  padding-bottom: 1.5em;
`;

const ContentContainer = styled.div`
  max-width: ${props => props.windowWidth - 260}px;
`;

const Icone = styled(Icon)`
  float: left !important;
  margin: 0 !important;
  margin-right: 1em !important;
`;


const Sidebar = (props) => {

  const { loading, sidebarIsVisible } = props;

  const [contentContainerWidth, setContentContainerWidth] = useState(window.innerWidth);
  const [tenantStatus, setTenantStatus] = useState(undefined);

  useEffect(() => {
    setTenantStatus(Storage.get('tenant_status'));
  }, []);

  const handleOnUpdate = (e, { width }) => setContentContainerWidth(width);

  return (
    <>
      {loading && (<Loader />)}

      <Pushable>
        <Responsive as={Bar} visible fireOnMount minWidth={Responsive.onlyComputer.minWidth}>
          <SidebarGrid fireOnMount>
            <Grid.Row>
              <Grid.Column>
                <Logo src={process.env.REACT_APP_INTERNAL_LOGO} centered />
              </Grid.Column>
            </Grid.Row>

            {isAuthenticated() && tenantStatus === 'activated' && (
              <Menu {...props} />
            )}
            <Grid.Row>
              <Grid.Column>
                <DivTitle id="container" >
                  <Grid.Row>
                    Canais de suporte
                  </Grid.Row>
                </DivTitle>
               
                <DivContact id="container" >
                  <Grid.Row>
                    <Icone name='phone'></Icone>(31) 3264-0337
                  </Grid.Row>
                  <Grid.Row>
                    <Icone name='whatsapp' ></Icone>(31) 99626-8329
                  </Grid.Row>
                  <Grid.Row>
                    <Icone name='whatsapp' ></Icone>(31) 97139-9474
                  </Grid.Row>
                </DivContact>
                <DivEmail>
                  <Grid.Row>
                      <Icone name='envelope' ></Icone>suportedevendas@parcelaexpress.com.br
                  </Grid.Row>
                </DivEmail>

              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <FooterLogoWrapper>
                  <Image src={footerLogo} />
                </FooterLogoWrapper>
              </Grid.Column>
            </Grid.Row>
          </SidebarGrid>
        </Responsive>

        <Responsive as={Bar}
          animation='push'
          visible={sidebarIsVisible}
          fireOnMount
          maxWidth={Responsive.onlyComputer.minWidth}>
          <SidebarGrid fireOnMount>
            <Grid.Row>
              <Grid.Column>
                <Logo src={process.env.REACT_APP_INTERNAL_LOGO} centered />
              </Grid.Column>
            </Grid.Row>

            <Menu {...props} />

            <Grid.Row>
              <Grid.Column>
                <FooterLogoWrapper>
                  <Image src={footerLogo} />
                </FooterLogoWrapper>
              </Grid.Column>
            </Grid.Row>
          </SidebarGrid>
        </Responsive>

        <Bar.Pusher>
          <Responsive as={Grid} fireOnMount onUpdate={handleOnUpdate} minWidth={Responsive.onlyComputer.minWidth}>
            <Grid.Row>
              <Grid.Column>
                <ContentContainer windowWidth={contentContainerWidth}>
                  {props.children}
                </ContentContainer>
              </Grid.Column>
            </Grid.Row>
          </Responsive>
          <Responsive as={Grid} fireOnMount maxWidth={Responsive.onlyComputer.minWidth}>
            <Grid.Row>
              <Grid.Column width={16}>
                {props.children}
              </Grid.Column>
            </Grid.Row>
          </Responsive>
        </Bar.Pusher>
      </Pushable>
    </>
  );
};

const mapStateToProps = state => ({
  loading: state.loading,
  sidebarIsVisible: state.sidebarIsVisible
});

export default connect(mapStateToProps)(Sidebar);
